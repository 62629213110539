@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.popup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F0F0F0;
  width: 45vw;
  height: 60vh;
  border-radius: 5px;
  outline: none;
}

.popup-modal2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F0F0F0;
  width: 40vw;
  height: 90vh;
  border-radius: 5px;
  outline: none;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  /* Add the desired blur effect here */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //top-[1vh] 
right-[9.5vw]
tall:top-[4.5vh] */
@media screen and (max-width: 760px) {
  .popup-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #F0F0F0;
    width: 65vw;
    height: 60vh;

    border-radius: 30px;
    outline: none;
  }

  .popup-modal2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    width: 95vw;
    height: 90vh;

    border-radius: 30px;
    outline: none;
  }



}